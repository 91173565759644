.page-raw-material {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: right;


    .btn {
      &.btn-create {
        display: inline-block;
        line-height: 36px;
        padding: 0px 15px;
      }
    }
  }

  .dmx-card-mt {
    margin-top: 0px;
  }
}
