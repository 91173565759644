.btn-refresh {
  width: 5rem;
  height: 2.1rem;
}

.page-products {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .btn {
    &.btn-create-product {
      line-height: 36px;
      padding: 0px 15px;
    }
  }
}

.data-table-extensions {
  .data-table-extensions-filter {
    .filter-text {
      width: 500px;
    }
    .filter-text::placeholder {
      color: rgb(87, 87, 87);
    }
  }
}

.dmx-modal-width {
  max-width: 850px !important;
}

.dmx-custom-height {
  height: 38px !important;
}

.dmx-modal-save-button {
  width: 200px !important;
  margin: auto;
}

.grid-conteiner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
