.form-group {
  &.hidden {
    display: none!important;
  }
}

.form-group {
  .select-form-control {
    padding: 0px;
   

    [class$="control"]{
      border: 0px;
      outline: 0px;
      padding: 0px;
      border-radius: 0.5rem;
      
      
      &:hover,
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
      }

      [class$="ValueContainer"]{
        
        [class$="Input"],
        [class$="singleValue"]{
          padding: 0px 0px 0px 10px;
        }
      }
  


      &:focus,
      &:active {
        border: 0px;
        outline: 0px;
        padding: 0px;
        outline: 0;
       
      }

      & > div {
        border: 0px;
        outline: 0px;
        padding: 0px;
        outline: 0;

        &:focus,
        &:active {
          border: 0px;
          outline: 0px;
          padding: 0px;
          outline: 0;
        }
      }
    }
  }
}