.page-sidebar {
  background-color: #fff !important;
}

.sidebar {
  background-color: #222d32 !important;
}

.logo-wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-dimex {
  width: 110px;
}

.sidebar-header {
  text-transform: none !important;
}

.page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
  margin-left: 0 !important;
}
