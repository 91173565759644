.btn-refresh {
  width: 5rem;
  height: 2.1rem;
}

.dmx-card-mt {
  select {
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }

  .col-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      justify-content: left;
    }

    @media (min-width: 992px) {
      margin-bottom: 0px;
    }

    span {
      line-height: 36px;
      padding-left: 15px;
    }
  }

  .col-btn-create {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      justify-content: flex-end;
    }
  }

  .btn {
    &.btn-create {
      width: 100%;
      display: inline-block;
      line-height: 34px;
      padding: 0px 15px;
      margin: 15px 0px;

      @media (min-width: 768px) {
        max-width: 300px;
      }

      @media (min-width: 992px) {
        margin: 0px;
        max-width: 250px;
      }
    }
  }
}

.dmx-actionButtons {
  font-size: medium !important;
}

.dmx-toggleArchive {
  text-align: right;
  margin-right: 2rem;
  padding-top: 0.4rem;
}

.dmx-action-button {
  font-size: 1.1rem;
  cursor: pointer;
}

.dmx-empty-table {
  padding: 3rem;
  font-size: large;
  color: #00000044;
  font-weight: bolder;
}

.dmx-float-r {
  float: right;
}

.dmx-float-l {
  float: left;
}

// .page-body {
//   .spinner-box {
//     .spinner {
//       width: 60px !important;
//       height: 60px !important;
//       margin-top: 8rem;
//     }
//   }
// }

.rdt_TableBody {
  cursor: pointer;
}

.projectmore .rdt_TableCol,
.projectmore .rdt_TableCell {
  font-size: 12px !important;
}
.projectmore .rdt_TableBody .rdt_ExpanderRow .rdt_TableCell .form-control {
  font-size: 12px;
}
.projectmore .rdt_TableBody .form-control {
  font-size: 11px;
  padding: 11px 9px;
  height: 38px;
}

.f-basis {
  flex: 0 0 15%;
}

.p-15 {
  padding: 15px;
}
