.dmx-card-mt {
  margin-top: 30px;
}

// -------------------------
//    React Stepzilla CSS
// -------------------------

$color-doing: #143fef;

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  font-weight: bold !important;
  color: $color-doing !important;
  border-bottom: 4px solid $color-doing !important;
}

ol.progtrckr li.progtrckr-done {
  // color: black;
  // border-bottom: 4px solid #5cb85c;
  color: silver !important;
  border-bottom: 4px solid $color-doing !important;
}

ol.progtrckr li:after {
  content: '\00a0\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '' !important;
  color: silver;
  background-color: rgba($color: #000000, $alpha: 0) !important;
  width: 0em !important;
  line-height: 0em !important;
  border-radius: 0em !important;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  // content: '' !important;
  // color: white;
  // background-color: $color-doing !important;
  // width: 1.2em;
  // line-height: 1.2em;
  // border-radius: 1.2em;
  content: '' !important;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0) !important;
  width: 0em !important;
  line-height: 0em !important;
  border-radius: 0em !important;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '' !important;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0) !important;
  width: 0em !important;
  line-height: 0em !important;
  border-radius: 0em !important;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.calendar-logistic-information {
  &.react-datepicker {
    .react-datepicker__day--today {
      background-color: transparent !important;
      color: black !important;
      font-weight: 600;
    
      &:hover {
        background-color: #f0f0f0 !important;
        color: black !important;
      }
    }
  
    .react-datepicker__current-month {
      text-transform: capitalize;
    }
  }
}
