.invoice-margins {
  margin: 30px;
}

.page-print-order {
  .button-print-container {
    position: sticky;
    top: 77px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;
    margin-bottom: 16px;

    @media (min-width: 992px) {
      top: 98px;
    }

    button {
      line-height: 40px;
      padding: 0px 30px;

      svg {
        margin-right: 5px;
      }
    }
  }

  .card {
    margin-top: 36px;
  }
}