.page-loader {
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.spinner-box {
  display: flex;
  justify-content: center;

  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.spinner-large {
  width: 65px;
  height: 65px;
}
.spinner-medium {
  width: 42px;
  height: 42px;
}
.spinner-small {
  width: 22px;
  height: 22px;
}
