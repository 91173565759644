.wrap-search-box {
  min-width: -webkit-fill-available;
  text-align: -webkit-center;
}

.no-data {
  padding: 1rem;
  font-size: large;
  color: #00000050;
  font-weight: bolder;
  text-align: center;
}
