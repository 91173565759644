.card-bags {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 16px;
  }
}


div[disabled]{
  &.rdt_Table {
    opacity: 1;
    pointer-events: all;

    .rdt_TableHead {
      opacity: .4;
    }
    .rdt_TableBody {
      .rdt_TableRow {
        .rdt_TableCell:not(:last-child){  
          opacity: .4;
          pointer-events: none;
        }
    
        .rdt_TableCell:last-child {
          & > :not(.persist-btn) {
            opacity: .4;
            pointer-events: none;
          }
        }
      }
    }
  }
}
// rdt_TableRow
// rdt_TableCol
// rdt_TableCol_Sortable
// rdt_TableCell
// rdt_TableHeader
// rdt_TableFooter
// rdt_TableHead
// rdt_TableHeadRow
// rdt_TableBody
// rdt_ExpanderRow