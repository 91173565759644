.production-order-new-bags-modal {
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      max-width: 165px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .bags-creation {
    text-align: center;

    &.error {
      margin: 0px;
      color: red;
    }
  }
}