.filter-by-archived {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  label {
    margin-right: 15px;
  }
}

.production-order-kanban {
  min-height: 300px;
}


.react-select__control {
  border-radius: 0.5rem!important;
  min-height: 36px!important;

  .react-select__indicators {
    .react-select__indicator {
      padding: 7px;
    }
  }
}