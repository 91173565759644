.dmx-input-feedback {
  color: #eb3636;
  font-size: 14px;
  text-align: center;
}

.product-observation-modal {
  .modal-header svg {
    color: #4a6a79;
  }
  .modal-body {
    background-color: #f5f7f9;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    span {
      display: block;
      text-align: right;
      color: #255c96;
    }
  }
  .observations-area {
    width: 100%;
    border-radius: 6px;
    font-size: 15px;
    &:disabled {
      background-color: white;
    }
  }
}
