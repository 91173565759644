.page-order-detail {
  .heading {
    display: flex;
    align-items: center;
    margin: 30px 0px;

    h5 {
      font-size: 24px;
      color: #666;
      font-weight: 300;
      display: inline-block;
      margin: 0px 20px 0px 0px;

      strong {
        color: #000;
      }
    }
  }

  .page-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0px !important;

    .breadcrumb {
      margin: 0px;
      margin-left: 0px !important;

      a {
        .btn {
          line-height: 38px;
          padding: 0px 20px;


          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .projectmore {
    margin-bottom: 60px;
  }

  .project-widgets {
    overflow: hidden;
  }

  .dmx-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:focus {
      box-shadow: 0 0 0 1px #2684ff !important;
      -webkit-box-shadow: 0 0 0 1px #2684ff !important;
    }
  }

  .card-body-detail {
    height: auto !important;
  }
}

.data-table-container {
  border: 1px solid #ccc;
  border-radius: 7px;

  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          font-size: 12px !important;
          padding-left: 15px;
          padding-right: 15px;

          .rdt_TableCol_Sortable {
            div {
              font-size: 12px !important;
            }
          }
        }
      }

    }

    .rdt_TableBody {
      cursor: pointer;

      .rdt_TableRow {
        .rdt_Table {
          border: 0px;
        }
        
        .rdt_TableCell {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  & > div {
    & > div {
      & > div {
        border: 0px;
      }
    }
  }
}

.projectdetails .card-header {
  font-size: 18px !important;
  padding: 0px !important;
  padding-bottom: 30px !important;
  padding-top: 30px !important;

  h5 {
    text-transform: initial !important;
    font-weight: 600;
    letter-spacing: normal !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
  }

  // background-color: #fff !important;
  background-color: transparent !important;

  border-bottom: none;
  border-bottom: 1px solid #f6f7fb !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dmx-delimeter {
  border-bottom: 1px solid #f6f7fb;
  align-items: center !important;
}

.infoCard {
  p {
    font-size: 16px !important;
  }
  textarea {
    width: -webkit-fill-available !important;
    height: 260px;
    border: none;
    outline: none;
    resize: none;
    padding: 10px;
  }
}

.pageTitle {
  font-size: 24px !important;
}

.page-header-left {
  display: flex;
  justify-content: space-between;
}

// OrderDetail Form
.dmx-form-control {
  display: inline !important;
  width: auto !important;
  padding: 0px !important;
  font-size: unset !important;
  border: none !important;
}

select[name='cardSelect'] {
  width: 100% !important;
  font-size: inherit !important;
  border: none !important;
  outline: none;
}

// Underline effect
.dmx-underline input {
  border: none;
  width: 100%;
}

.dmx-underline:after {
  display: block;
  content: '';
  border-bottom: 3px solid #4466f2;
  transform: scaleX(0);
  transition: transform 0.5s;
  transform-origin: 0% 50%;
}

.dmx-underline:hover:after {
  transform: scaleX(1);
}

// Justify card
.dmx-headerCard {
  display: flex;
  justify-content: flex-end;

  h5 {
    font-size: 18px !important;
  }
}
.justify {
  width: 50px;
  display: contents;
}
.justify-start {
  margin-right: auto;
  display: flex;
}

// Edit card button
.btnEdit {
  color: #ffffff;
  padding: 5px !important;
  width: auto !important;
  background-color: #006dcc;
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  &:hover {
    color: #ffffff;
    background-color: #0044cc;
    text-decoration: none;
    background-position: 0 -15px;
    transition: background-position 0.1s linear;
  }
  &:active {
    background-image: none;
    outline: 0;
  }
}

.breadcrumb-style {
  margin: 0px;
  list-style: none !important;
  padding: 0;
}
.btnBack {
  background: green;
  color : white;
  padding: 5px;
  border: 1px solid white;
  border-radius: 20px;


}

.calendar-logistic-form {
  &.react-datepicker {
    .react-datepicker__day--today {
      background-color: transparent !important;
      color: black !important;
      font-weight: 600;

      &:hover {
        background-color: #f0f0f0 !important;
        color: black !important;
      }
    }

    .react-datepicker__current-month {
      text-transform: capitalize;
    }
  }
}

.text-obs {
  // color: #545884;
  color: #4a6a79;
}
.product-observation-modal {
  .modal-header svg {
    color: #4a6a79;
  }
  .modal-body {
    background-color: #f5f7f9;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    span {
      display: block;
      text-align: right;
      color: #255c96;
    }
  }
  .observations-area {
    width: 100%;
    border-radius: 6px;
    font-size: 15px;
    &:disabled {
      background-color: white;
    }
  }
}
