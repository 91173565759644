.btn-refresh {
  width: 5rem;
  height: 2.1rem;
}

.page-clients {
  .page-header {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .btn {
    &.btn-create {
      line-height: 36px;
      padding: 0px 15px;
    }
  }
}

.data-table-extensions {
  .data-table-extensions-filter {
    .filter-text {
      width: 500px;
    }
    .filter-text::placeholder {
      color: rgb(87, 87, 87);
    }
  }
}

.dmx-modal-width {
  max-width: 850px !important;
}

.dmx-modal-save-button {
  width: 200px !important;
  margin: auto;
}

.dmx-modal-custom-checkbox {
  width: 10% !important;
  float: left !important;
  margin-top: 3px !important;

  &:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}
