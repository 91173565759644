.dropdown-user {
    &.onhover-show-div {
        background-color: #fff;
        width: 180px;
        padding: 15px 0px;
        top: 60px;
        left: -126px;
        transform: translateY(0px);
        border-radius: 8px;
    }

    &:before,
    &:after {
        left: 141px !important;
    }

    li {
        width: 100%;
        padding: 10px 15px;

        &:hover {
            background-color: #eee;
        }

        a {
            color: black;
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }
    }
}