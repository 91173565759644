.subrow-datepicker {
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  .MyClass .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
}
