.button-transparent {
  background-color: transparent;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.nav-wrapper-left {
  display: flex;
  align-items: center;
  padding: 0px 30px;
  
  .title-page {
    font-size: 24px;
    color: #666;
    font-weight: 300;
    display: inline-block;
    margin: 0px 0px 0px 15px;
    overflow: hidden;
      }
}

.nav-wrapper-right {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.page-wrapper {
  .page-main-header {
    .main-header-right {
      padding: 15px;
      justify-content: flex-end;
      align-items: center;
      
      .nav-right > ul.nav-menus {
        top: 0;
        position: relative;
        z-index: 1;
        background-color: transparent;
        transition: none;
        box-shadow: none;
        width: 100%;
        left: auto;
        transform: none;
        opacity: 1;
        visibility: visible;
        justify-content: flex-end;
        
        @media (min-width: 768px) {
        }
      }
    }
  }
}

