.select-state-aligned {
  display: flex;
  align-items: center;
  text-align: center;
  width: 200px;
  font-size: 22px;
}

.select-state-flex {
  font-size: 22px;
  display: flex;
  align-items: center;

  .badge {
    font-weight: normal;
    display: block;
  }
  button {
    display: flex;
  }
}