.btn-refresh {
  width: 5rem;
  height: 2.1rem;
}

.dmx-empty-table {
  padding: 3rem;
  font-size: large;
  color: #00000044;
  font-weight: bolder;
}

.dmx-empty-field {
  padding: 3rem;
  font-size: smaller;
  color: #00000044;
  font-weight: bolder;
}
