.component-invoice {
  &.invoice-margins {
    margin: 30px;
  }

  .header-pdf {
    table {
      tr {
        &:first-child {
          border-bottom: 1px solid #cccccc;
        }
  
        td {
          border-top: 0px;
        }
      }
    }
  }

  .body-pdf {
    margin-bottom: 70px;

    table {
      thead {
        background-color: #eeeeee;

        th {
          border-top: 0px;
          border-bottom: 0px;
        }
      }

      tbody {
        tr {
          &.pd-header {
            td {
              background-color: #f5f5f5;

              &:first-child {
                background-color: transparent;
              }
            }
          }

          &.pd-body {
            td {
              &:first-child {
                border-top: 0px;
              }
            }
          }

          &.prod-row {
            &:first-child {
              border-top: 0px;
            }
          }
        }
      }
    }
  }

  .footer-pdf {
    margin-bottom: 70px;

    .table {
      tbody {
        tr {
          border-bottom: 1px solid #cccccc;
          
          &:last-child {
            border: 0px;
          }

          td {
            border: 0px;
          }
        }
      }
    }
  }

  .brand-contact-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.there-are-no-products {
  padding: 3rem;
  font-size: large;
  color: #00000044;
  font-weight: bolder;
}

.pd-header {
  border-style: hidden;
  font-weight: bold;
}

.prod-row {
  border-top: 2px solid #000;
}
