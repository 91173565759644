.dmx-attachmentButtons {
  display: flex !important;
  justify-content: space-evenly !important;
  font-size: 1.25rem !important;
}

.dmx-custom-attachment {
  width: 10% !important;
  padding: 5px !important;
}

.customBlockModal {
  .modal-content {
    background-color: transparent !important;
    border: none !important;
  }
}

.dmx-custom-images {
  img {
    width: 90px !important;
  }
}
